const cardListConfig = [
    {
        'content': {
            'titleId': 'latest_publications',
            'disableTranslation': false,
            'descriptionId': 'latest_pub_card_description',
            'urlPath': '/publikationen/?sort=latest',
        },
        'media': {
            'alt': 'Neueste Publikationen',
            'src': '/zeitung.jpg',
            'title': 'Neueste Publikationen',
        },
    },
    {
        'content': {
            'titleId': 'truth_card_title',
            'disableTranslation': false,
            'descriptionId': 'truth_card_description',
            'urlPath': '/publikationen/?tag=wahrheit_epistemologie',
        },
        'media': {
            'alt': 'Truth',
            'src': '/wahrheit.jpg',
            'title': 'truth',
        },
    },
    {
        'content': {
            'titleId': 'existence_of_god',
            'disableTranslation': false,
            'descriptionId': 'god_card_description',
            'urlPath': '/publikationen/?tag=existenzgottes',
        },
        'media': {
            'alt': 'Gott',
            'src': '/gott-galaxy.jpg',
            'title': 'Gott',
        },
    },
    {
        'content': {
            'titleId': 'jesus_topic_card',
            'disableTranslation': false,
            'descriptionId': 'jesus_card_description',
            'urlPath': '/publikationen/?tag=jesus',
        },
        'media': {
            'alt': 'Jesus',
            'src': '/jesus.jpg',
            'title': 'Jesus',
        },
    },
    {
        'content': {
            'titleId': 'the_bible',
            'disableTranslation': false,
            'descriptionId': 'bible_card_description',
            'urlPath': '/publikationen/?tag=diebibel',
        },
        'media': {
            'alt': 'Die Bibel',
            'src': '/adult-bibel.jpg',
            'title': 'Die Bibel',
        },
    },
    {
        'content': {
            'titleId': 'morality_evil',
            'disableTranslation': false,
            'descriptionId': 'morality_evil_description',
            'urlPath': '/publikationen/?tag=moral_boesartigkeit',
        },
        'media': {
            'alt': 'Moral und Bösartigkeit',
            'src': '/alone.jpg',
            'title': 'Moral und Bösartigkeit',
        },
    },
    {
        'content': {
            'titleId': 'atheism',
            'disableTranslation': false,
            'descriptionId': 'atheism_description',
            'urlPath': '/publikationen/?tag=atheismus',
        },
        'media': {
            'alt': 'Atheismus',
            'src': '/atheist.png',
            'title': 'Atheismus',
        },
    },
];

export default cardListConfig;

import Publication from '../../../../domain/Publication/Publication';
import React, { ReactElement } from 'react';
import * as style from './index.module.css';
import { Button } from '@mui/material';
import { Link } from 'gatsby';
import RouteGenerator from '../../../../infrastructure/Navigation/RouteGenerator';
import { TLocale } from '../../../../infrastructure/Type/Locale';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { Schedule } from '@mui/icons-material';

export interface MastheadProps
{
    publication: Publication;
    locale: TLocale;
}

export default function Masthead(props: MastheadProps): ReactElement
{
    const { publication } = props;

    return (
        <div className={style.container}>
            <div
                style={{
                    backgroundImage: `url(${publication.getImage()!})`,
                }}
                className={style.image_element}
            >
                <div className={style.image_overlay}>
                    <div className={style.image_text_content}>
                        <div className={style.image_text_restricted_width}>
                            <FormattedMessage id="latest_publication"/>
                            <h1 className={style.text_content_title}>{publication.getTitle()}</h1>
                            <div className={style.text_content_author_date}>
                                <b className={style.text_content_author}>{publication.getOriginalAuthor()}</b>
                                    , <span className={style.text_content_date}>
                                    <FormattedDate
                                        value={publication.getPublishToGlaubensfutterDate()}
                                        year="numeric"
                                        month="long"
                                        day="2-digit"
                                    />
                                </span>
                            </div>
                        </div>
                        {
                            publication.getEstimatedReadingTime() && (
                                <div className={style.text_content_reading_time}>
                                    <Schedule style={{
                                        color: '#FFFFFF',
                                        fontSize: '18px',
                                        marginRight: '3px',
                                    }}/> <span style={{ marginLeft: '3px' }}>
                                        {publication.getEstimatedReadingTime()} Min.</span>
                                </div>)
                        }

                        <Link
                            className={style.text_content_button_link}
                            to={
                                RouteGenerator.forSinglePublicationPage(
                                    publication.getSlug(),
                                    publication.getId(),
                                    props.locale
                                ).getPath()
                            }>
                            <Button
                                variant="outlined"
                                style={{
                                    border: '1px solid white',
                                    marginTop: '3rem',
                                    textDecoration: 'none',
                                    color: 'white',
                                }}
                            >
                                <FormattedMessage id="view"/>
                            </Button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

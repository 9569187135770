import React from 'react';
import PropTypes from 'prop-types';

import { FormattedMessage } from 'react-intl';
import CardList from '../../molecules/CardList';
import DesktopLinks from '../../molecules/DesktopLinks';
import globalComponentMountOperations from '../../../infrastructure/utilities/globalComponentMountOperations';
import Head from '../../atoms/Head';
import landingPageCardListConfig from './landingPage.cardlistconfig';
import RouteGenerator from '../../../infrastructure/Navigation/RouteGenerator';

import desktopLinksConfig from './desktopLinksConfig.json';
import Masthead from '../../molecules/Publication/MastHead/Masthead';
import { GFBrand } from '../../models/GFBrand';

class LandingPage extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            latestPublication: null,
        };
    }

    componentDidMount()
    {
        globalComponentMountOperations.handleRouteComponentMount();
        const client = this.props.getHttpClient();
        client.getLatestPublication(this.props.locale)
            .then((publication) =>
            {
                this.setState({
                    latestPublication: publication,
                });
            });
    }

    render()
    {
        const { locale } = this.props;
        const { latestPublication } = this.state;

        return (
            <section className="layout-lower-spacing">
                <Head
                    locale={locale}
                    ogImage={RouteGenerator.forPublicUrl('/wahrheit.jpg')}
                    ogTitle="Glaubensfutter"
                    ogDescription={GFBrand.BRAND_DESCRIPTION}
                    ogUrl={RouteGenerator.forLandingPage(locale).getFullUrl()}
                />
                <div className="page-header-wrapper">
                    <img style={{ width: '7rem' }} src="/gf-header-icon.png" className="app-icon" alt="app-icon"/>
                    <h3 className="page-header-title-landingpage">
                        GLAUBENSFUTTER
                    </h3>
                    <p className="app-tagline">
                        <FormattedMessage id="tagline"/>
                    </p>
                </div>
                <DesktopLinks locale={locale} linkConfig={desktopLinksConfig}/>
                {
                    latestPublication && <Masthead locale={locale} publication={latestPublication}/>
                }
                <div className="static-topics-container">
                    <CardList
                        cardListConfig={landingPageCardListConfig}
                        locale={locale}
                        cardType="topicCard"
                    />
                </div>
            </section>

        );
    }
}

LandingPage.propTypes = {
    locale: PropTypes.string,
    intl: PropTypes.string,
    pageContext: PropTypes.object,
    getHttpClient: PropTypes.func,
};

export default LandingPage;

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { FormattedMessage } from 'react-intl';

import AnchorLink from '../../atoms/AnchorLink';
import { buildUrlWithLocaleAndPath } from '../../../infrastructure/utilities/deriveUrlFromLocale';

const DesktopLinks = ({ linkConfig, locale }) =>
{
    const desktopLinkContent = linkConfig.map((link, index) =>
    {
        let linkContent;
        if (link.isExternal)
        {
            const externalProps = {
                target: link.targetBlank ? '_blank' : null,
                rel: link.targetBlank ? 'noreferrer noopener' : null,
            };
            linkContent = <AnchorLink href={link.target} {...externalProps}><FormattedMessage id={link.textId} /></AnchorLink>;
        }
        else
        {
            if (!link.target || link.target[0] !== '/')
            {
                return null;
            }
            linkContent = (
                <Link
                    to={buildUrlWithLocaleAndPath(locale, link.target)}
                    itemProp="url"
                >
                    <FormattedMessage itemProp="title" id={link.textId} />
                </Link>
            );
        }

        return (
            <li key={index}>{linkContent}</li>
        );
    });

    return (
        <div className="hr-navlinks-container">
            <ul>
                {desktopLinkContent}
            </ul>
            <hr/>
        </div>
    );
};

DesktopLinks.propTypes = {
    locale: PropTypes.string,
    linkConfig: PropTypes.array,
};

export default DesktopLinks;

// extracted by mini-css-extract-plugin
export var card_component = "index-module--card_component--wZVUR";
export var container = "index-module--container--lGiSR";
export var image_element = "index-module--image_element--QGfFT";
export var image_overlay = "index-module--image_overlay--Eq8XR";
export var image_text_content = "index-module--image_text_content--csy-p";
export var image_text_restricted_width = "index-module--image_text_restricted_width--B7Lhp";
export var text_content_author = "index-module--text_content_author--WXT48";
export var text_content_author_date = "index-module--text_content_author_date--5R0IK";
export var text_content_button_link = "index-module--text_content_button_link--qJz1u";
export var text_content_date = "index-module--text_content_date--Hqbof";
export var text_content_description = "index-module--text_content_description--QYRAw";
export var text_content_reading_time = "index-module--text_content_reading_time--JcOME";
export var text_content_title = "index-module--text_content_title--hhbGA";
import LandingPage from '../modules/ui/pages/LandingPage';
import withGlobalAppLayout from '../modules/ui/containers/HOC/withGlobalAppLayout';
import { ReactElement } from 'react';
import PublicationClient from '../modules/infrastructure/Publication/Client/Client';

export default function index(props: any): ReactElement
{
    return withGlobalAppLayout(LandingPage, {
        locale: props.pageContext.locale,
        hideGfLogo: true,
        getHttpClient: () => new PublicationClient(),
        ...props,
    });
}
